import {ChangeDetectionStrategy, Component, HostBinding, input} from '@angular/core';
import {Colors} from '@core/constants/colors.constants';
import {IconComponent, IconSize} from '@shared/components/ui/icons/icon/icon.component';

@Component({
  selector: 'app-card-balance',
  imports: [
    IconComponent
  ],
  templateUrl: './card-balance.component.html',
  styleUrl: './card-balance.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardBalanceComponent {
  appearance = input<CardBalanceAppearance>(CardBalanceAppearance.DARK);
  balance = input.required<number>();

  @HostBinding('class')
  get class() {
    return this.appearance();
  }
  protected readonly Colors = Colors;
  protected readonly IconSize = IconSize;
}

export enum CardBalanceAppearance {
  WHITE = 'white',
  DARK = 'dark',
}
